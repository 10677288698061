import { useQuery } from 'react-query';

function useMasseuseDetails(masseuseId) {
  const fetchMasseuseDetails = async () => {
    const endpoint = `https://mydynasty.ca/cd/api/v1/masseuses/${masseuseId}`;

    try {
      const response = await fetch(endpoint);

      if (!response.ok) {
        throw new Error(`Failed to fetch masseuse details: ${response.status}`);
      }

      const data = await response.json();
      return data;
    } catch (error) {
      throw new Error('Failed to fetch masseuse details');
    }
  };

  const queryKey = ['masseuseDetails', masseuseId];

  const { data, error, isLoading } = useQuery(queryKey, fetchMasseuseDetails, {
    cacheTime: Infinity,
    staleTime: Infinity,
    onError: (err) => {
      console.error(err);
    },
  });

  return {
    masseuseDetails: data,
    isLoading,
    error,
  };
}

export default useMasseuseDetails;
