import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Container, Grid } from '@mui/material';
import { Link } from 'react-router-dom';
import PollIcon from '@mui/icons-material/Poll';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import { useSelector } from 'react-redux';
import userChecker from '../utils/userChecker';

export default function HomeScreen() {
  const adminUserLogin = useSelector((state) => state.adminUserLogin);
  const { adminUserInfo } = adminUserLogin;
  const { user } = adminUserInfo;

  return (
    <Container>
      <Grid container spacing={2} mt={5} mb={2}>
        <Grid item xs={12} sm={12}>
          <Box sx={{ minWidth: 275 }}>
            <Card variant="outlined">
              <CardContent>
                <PollIcon color="primary" sx={{ fontSize: 50 }} />
                <Typography
                  sx={{ fontSize: 20 }}
                  color="text.secondary"
                  gutterBottom
                ></Typography>
                <Typography variant="h5" component="div" gutterBottom>
                  Session Review
                </Typography>

                <Typography variant="body2" color="text.secondary">
                  Displays today's sessions and there a customer can submit a
                  overall rating and optionally complete a detailed review.
                </Typography>
              </CardContent>
              <CardActions>
                <Link to={'/session-review'}>
                  <Button size="small">Go to Session Review</Button>{' '}
                </Link>
                {user && userChecker(user.kind) && (
                  <Link to={'/session-review/result'}>
                    <Button size="small">Results</Button>{' '}
                  </Link>
                )}
                {user && userChecker(user.kind) && (
                  <Link to={'/session-review/detailed-result'}>
                    <Button size="small">Detailed Results</Button>{' '}
                  </Link>
                )}
              </CardActions>
            </Card>
          </Box>
        </Grid>
        {/* <Grid item xs={12} sm={6}>
          <Box sx={{ minWidth: 275 }}>
            <Card variant="outlined">
              <CardContent>
                <ContentPasteIcon color="primary" sx={{ fontSize: 50 }} />
                <Typography
                  sx={{ fontSize: 20 }}
                  color="text.secondary"
                  gutterBottom
                ></Typography>
                <Typography variant="h5" component="div" gutterBottom>
                  Simple Survey
                </Typography>

                <Typography variant="body2" color="text.secondary">
                  A simple survey that displays masseuses that are available
                  today and they can be rated 1-10.
                </Typography>
              </CardContent>
              <CardActions>
                <Link to={'/simple'}>
                  <Button size="small">Go to Simple Survey</Button>{' '}
                </Link>
                {user && userChecker(user.kind) && (
                  <Link to={'/simple/result'}>
                    <Button size="small">Results</Button>{' '}
                  </Link>
                )}
              </CardActions>
            </Card>
          </Box>
        </Grid> */}
      </Grid>
    </Container>
  );
}
