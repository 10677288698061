import { useQuery } from 'react-query';
import axios from 'axios';

function useSimpleList({
  token,
  masseuse,
  page,
  page_size,
  start_date,
  end_date,
}) {
  const fetchSimpleList = async () => {
    const endpoint = `https://mydynasty.ca/ext/api/v1/surveys/simple/`;
    const headers = {
      Authorization: token,
    };

    const params = {
      page: page ?? '',
      page_size: page_size ?? '',
      masseuse: masseuse ?? '',
      start_date: start_date ?? '',
      end_date: end_date ?? '',
    };

    try {
      const { data } = await axios.get(endpoint, { params, headers });

      return data;
    } catch (error) {
      throw new Error('Failed to fetch Simple List');
    }
  };

  const queryKey = [
    'simpleList',
    masseuse,
    page,
    page_size,
    start_date,
    end_date,
  ];

  const { data, error, isLoading } = useQuery(queryKey, fetchSimpleList, {
    cacheTime: Infinity,
    staleTime: Infinity,
    onError: (err) => {
      console.error(err);
    },
  });

  return {
    rows: data,
    isLoading,
    error,
  };
}

export default useSimpleList;
