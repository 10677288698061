import { useQuery } from 'react-query';
import axios from 'axios';

function useAvailableMasseuses(token) {
  const fetchAvailableMasseuses = async () => {
    const endpoint = `https://mydynasty.ca/ext/api/v1/surveys/simple/availables/`;
    const headers = {
      Authorization: token,
    };

    try {
      const response = await axios.get(endpoint, { headers });
      return response.data;
    } catch (error) {
      throw new Error('Failed to fetch available masseuses');
    }
  };

  const queryKey = ['availableMasseuses'];

  const { data, error, isLoading } = useQuery(
    queryKey,
    fetchAvailableMasseuses,
    {
      cacheTime: Infinity,
      staleTime: Infinity,
      onError: (err) => {
        console.error(err);
      },
    }
  );

  return {
    availableMasseuses: data,
    isLoading,
    error,
  };
}

export default useAvailableMasseuses;
