export default function sessionsFormatter(array1) {
  const result = [];

  if (!array1) return result;

  if (!Array.isArray(array1)) {
    throw new Error('The input must be an array');
  }

  if (array1.length === 0) return result;

  array1.forEach((item) => {
    let masseusesInSession = item.masseuses;
    item.masseuses.forEach((masseuse) => {
      // Check if the masseuse already exists in the result
      let masseuseEntry = result.find(
        (entry) => entry.masseuse_id === masseuse.id
      );

      // If the masseuse does not exist, add it to the result
      if (!masseuseEntry) {
        masseuseEntry = {
          masseuse: masseuse.name,
          masseuse_id: masseuse.id,
          sessions: [],
        };
        result.push(masseuseEntry);
      }

      // Add session information to the masseuse's sessions array
      const sessionInfo = {
        session_id: item.id,
        survey_status: item.survey_status,
        survey_time: item.survey_time,
        session_type: item.session_type,
        working_date: item.working_date,
        start_time: item.start_time,
        end_time: item.end_time,
        member: item.member,
        customer: item.customer,
        locker: item.locker,
        otherMasseuses: masseusesInSession.filter((m) => m.id !== masseuse.id),
      };

      masseuseEntry.sessions.push(sessionInfo);
    });
  });

  return result;
}
