export const API_URI = 'https://mydynasty.ca/cd/api/v1';
export const CD_API_URI = 'https://mydynasty.ca/api/v1';
// export const API_URI = 'http://127.0.0.1:8000/cd/api/v1';
export const GA_TRACKING_ID = 'G-BGDLEP2KMB';

export const LOCKER_CHOICES = {
  0: 'None',
  1: '1-5',
  6: '6-19',
  20: '20-27',
  28: '28-38',
};

export const ROBE_CHOICES = {
  0: 'None',
  1: 'M',
  2: 'L',
  3: 'XL',
  4: 'XXL',
  5: 'XXXL',
};

export const SHOE_CHOICES = {
  0: 'None',
  1: 'Small (black)',
  2: 'Large (beige)',
};

export const SHORTS_CHOICES = {
  0: 'None',
  1: 'M',
  2: 'L',
  3: 'XL',
  4: 'XXL',
  5: 'XXXL',
};

export const USER_KIND_CHOICES = {
  1: 'Masseuse',
  2: 'Reception',
  3: 'Admin',
  4: 'Employee',
  5: 'Concierge',
  6: 'Bar',
  7: 'Laundry',
  8: 'Member', // This type was not originally included in your mapping, assuming to add it based on the sequence.
  10: 'Manager',
  11: 'Assistant Manager',
};

export const QUESTION_KIND_CHOICES = {
  1: 'KIND_RATING',
  2: 'KIND_YES_NO',
  3: 'KIND_CHOICES_3',
};

export const ANS_KIND_THREE = {
  1.0: '1-5 KM',
  2.0: '6-20 KM',
  3.0: 'Over 20 KM',
};

export const ANS_KIND_TWO = {
  1.0: 'Yes',
  2.0: 'No',
};
