import axios from 'axios';
import {
  SIMPLE_SUBMIT_SURVEY_FAIL,
  SIMPLE_SUBMIT_SURVEY_REQUEST,
  SIMPLE_SUBMIT_SURVEY_SUCCESS,
} from '../constants/simpleSurveyConstants';
import formatError from '../utils/errors';
import { setAlert } from './alertActions';
import { API_URI, CD_API_URI } from '../constants/appConstants';

export const submitSimpleSurvey =
  (simpleSurveyAnswer) => async (dispatch, getState) => {
    try {
      dispatch({
        type: SIMPLE_SUBMIT_SURVEY_REQUEST,
      });

      const {
        adminUserLogin: { adminUserInfo },
      } = getState();

      const config = {
        headers: {
          'Content-Type': 'Application/json',
          Authorization: adminUserInfo.token,
        },
      };

      const { data } = await axios.post(
        `https://mydynasty.ca/ext/api/v1/surveys/simple/`,
        simpleSurveyAnswer,
        config
      );

      dispatch({
        type: SIMPLE_SUBMIT_SURVEY_SUCCESS,
        payload: data,
      });
    } catch (error) {
      // console.log(error.response);
      const errorText = formatError(error.response.data);
      if (error.response.status !== 429) dispatch(setAlert(errorText, 'error'));

      dispatch({
        type: SIMPLE_SUBMIT_SURVEY_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
