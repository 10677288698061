import { Container, Grid } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import useSurveyQuestions from '../hooks/useSurveyQuestions';
import AnswerChart from '../components/AnswerChart';

const SessionSurveyDetailedResultScreen = () => {
  const adminUserLogin = useSelector((state) => state.adminUserLogin);
  const { adminUserInfo } = adminUserLogin;

  const { questions } = useSurveyQuestions({
    token: adminUserInfo?.token,
  });

  return (
    <Grid
      container
      spacing={5}
      mt={1}
      mb={2}
      p={{
        xs: 1,
        sm: 5,
      }}
    >
      {questions &&
        questions.length > 0 &&
        questions.map((question) => (
          <Grid item key={question.id} xs={12} sm={6}>
            <AnswerChart question={question} token={adminUserInfo.token} />
          </Grid>
        ))}
    </Grid>
  );
};

export default SessionSurveyDetailedResultScreen;
