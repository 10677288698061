import {
  Avatar,
  Box,
  Collapse,
  IconButton,
  Rating,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import RecentForm from './RecentForm';
import useSimpleList from '../hooks/useSimpleList';
import { format, parseISO } from 'date-fns';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import techniqueChecker from '../utils/techniqueChecker';

const ExpandableRow = (props) => {
  const { row, token, recentFormPageSize } = props;
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [page_size, setPage_size] = useState(5);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const { rows, isLoading, error } = useSimpleList({
    token,
    masseuse: row.masseuse,
    page: page + 1,
    page_size,
    start_date: startDate?.toISOString().split('T')[0],
    end_date: endDate?.toISOString().split('T')[0],
  });

  const formatTime = (time) => {
    const result = parseISO(time, { timeZone: 'Toronto/Canada' });

    const output = format(result, 'PPPp', { timeZone: 'Toronto/Canada' });

    return `${output}`;
  };

  const handleStartDateChange = (newValue) => {
    setStartDate(newValue);
  };

  const handleEndDateChange = (newValue) => {
    setEndDate(newValue);
  };

  const handleChangePage = (event, newPage) => {
    // console.log(newPage + 1);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage_size(parseInt(event.target.value, 10));
    setPage(0);
  };

  const profilePic = () => {
    if (
      (row.profile_visible === true || row.profile_visible === false) &&
      techniqueChecker(row.technique)
    ) {
      return row.image;
    } else if (
      (row.profile_visible === true || row.profile_visible === false) &&
      row.technique === 'Shiatsu'
    ) {
      return 'https://clubdynasty.ca/wp-content/uploads/2023/10/Female_Silouhette-scaled-1.jpg';
    }
  };

  return (
    <React.Fragment>
      <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>

        <TableCell align="center">
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Avatar
              alt={row.name}
              src={profilePic()}
              style={{ marginRight: '10px' }}
              sx={{ width: 56, height: 56 }}
            />{' '}
            &nbsp;
            <Typography style={{ fontWeight: 'bold' }}>{row.name}</Typography>
          </Box>
        </TableCell>
        {/* <TableCell align="left">{row.technique}</TableCell> */}
        <TableCell align="right">{row.count}</TableCell>
        <TableCell align="right">{row.average.toFixed(2)}</TableCell>
        {/* <TableCell align="center">
          <RecentForm
            token={token}
            masseuse={row.id}
            page={1}
            page_size={recentFormPageSize}
          />
        </TableCell> */}
      </TableRow>
      <TableRow>
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0, border: !open && 0 }}
          colSpan={6}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 3 }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: '10px',
                }}
              >
                <Typography variant="h6" component="div" mr={2}>
                  {row.name}'s Ratings History
                </Typography>

                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  style={{ marginRight: '15px' }}
                >
                  <DatePicker
                    inputFormat="MM/dd/yyyy"
                    label="Start Date"
                    value={startDate}
                    onChange={handleStartDateChange}
                    disableFuture
                    slotProps={{
                      textField: {
                        size: 'small',
                        sx: {
                          marginRight: '20px',
                        },
                      },
                    }}
                  />
                </LocalizationProvider>

                <Tooltip title={!startDate && 'Select start date first'}>
                  <div>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        inputFormat="MM/dd/yyyy"
                        label="End Date"
                        value={endDate}
                        onChange={handleEndDateChange}
                        disableFuture
                        minDate={startDate && startDate}
                        disabled={!startDate}
                        slotProps={{
                          textField: {
                            size: 'small',
                          },
                        }}
                      />
                    </LocalizationProvider>
                  </div>
                </Tooltip>
              </div>

              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Value</TableCell>
                    <TableCell>Stars</TableCell>
                    <TableCell>Date</TableCell>
                    <TableCell>Working Date</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {isLoading &&
                    Array.from(new Array(5)).map((_, index) => (
                      <TableRow key={index}>
                        <TableCell colSpan={4}>
                          <Skeleton />
                        </TableCell>
                      </TableRow>
                    ))}
                  {!isLoading &&
                    rows &&
                    rows.results.map((r) => (
                      <TableRow key={r.id}>
                        <TableCell component="th" scope="row">
                          <span
                            style={{
                              fontWeight: 'bold',
                              marginRight: '10px',
                            }}
                          >
                            {r.value}
                          </span>
                        </TableCell>
                        <TableCell>
                          <Rating
                            value={r.value}
                            size="small"
                            max={10}
                            readOnly
                          />
                        </TableCell>
                        <TableCell>{formatTime(r.created)}</TableCell>
                        <TableCell>{r.working_date}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={rows && rows.count ? rows.count : 0}
                rowsPerPage={page_size}
                page={isLoading ? 0 : page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default ExpandableRow;
