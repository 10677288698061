import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { ANS_KIND_TWO } from '../constants/appConstants';

ChartJS.register(ArcElement, Tooltip, Legend);

const AnswerPieTwoChoices = ({ results }) => {
  const data = {
    labels: results?.map((r) => {
      return ANS_KIND_TWO[r.score];
    }),
    datasets: [
      {
        label: '% of Submissions',
        data: results?.map((r) => {
          return (r.scorePercentage * 100).toFixed(2);
        }),
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div>
        <Pie data={data} />{' '}
      </div>
    </div>
  );
};

export default AnswerPieTwoChoices;
