import { useQuery } from 'react-query';
import axios from 'axios';
import techniqueChecker from '../utils/techniqueChecker';

function useSimpleAggregates({
  token,
  page,
  page_size,
  start_date,
  end_date,
  order,
  order_by,
  technique,
  status,
}) {
  const fetchSimpleAggregates = async () => {
    const endpoint = `https://mydynasty.ca/ext/api/v1/surveys/simple/aggregates/`;
    const headers = {
      Authorization: token,
    };

    const params = {
      start_date: start_date ?? '',
      end_date: end_date ?? '',
    };

    try {
      const { data } = await axios.get(endpoint, { params, headers });

      const res = await fetch(
        'https://mydynasty.ca/cd/api/v1/masseuses/?&page=1&page_size=1000'
      );

      if (!res.ok) {
        throw new Error(`Failed to fetch masseuse details: ${res.status}`);
      }
      const masseuses = await res.json();

      let modifiedMasseuses = [];

      data.forEach((masseuse) => {
        let masseuseDetails = masseuses.results.find(
          (m) => m.id === masseuse.masseuse
        );

        let masseuseTemp = {};
        masseuseTemp = { ...masseuse, ...masseuseDetails };

        modifiedMasseuses.push(masseuseTemp);
      });

      let filteredMasseuses = [];

      if (status === true) {
        filteredMasseuses = modifiedMasseuses.filter(
          (masseuse) => masseuse.profile_visible === true
        );
      } else if (status === false) {
        filteredMasseuses = modifiedMasseuses.filter(
          (masseuse) => masseuse.profile_visible === false
        );
      } else if (status === null) {
        filteredMasseuses = [...modifiedMasseuses];
      }

      if (techniqueChecker(technique)) {
        let sensualMasseuses = filteredMasseuses.filter((masseuse) =>
          techniqueChecker(masseuse.technique)
        );

        return sensualMasseuses;
      } else if (technique === 'Shiatsu') {
        let shiatsuMasseuses = filteredMasseuses.filter(
          (masseuse) => masseuse.technique === 'Shiatsu'
        );
        return shiatsuMasseuses;
      } else {
        return filteredMasseuses;
      }
    } catch (error) {
      throw new Error('Failed to fetch Simple Aggregates');
    }
  };

  const queryKey = [
    'simpleAggregates',
    start_date,
    end_date,
    technique,
    status,
  ];

  const { data, error, isLoading } = useQuery(queryKey, fetchSimpleAggregates, {
    cacheTime: Infinity,
    staleTime: Infinity,
    onError: (err) => {
      console.error(err);
    },
  });

  return {
    rows: data,
    isLoading,
    error,
  };
}

export default useSimpleAggregates;
