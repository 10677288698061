import {
  Avatar,
  Box,
  Collapse,
  IconButton,
  LinearProgress,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import React, { lazy, useState, Suspense } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { format, parseISO } from 'date-fns';
import techniqueChecker from '../utils/techniqueChecker';
import { Link } from 'react-router-dom';

const ExpandableRowContent = lazy(() => import('./ExpandableRowContent'));

const ExpandableRowSessionResult = (props) => {
  const { row, token, recentFormPageSize } = props;
  const [open, setOpen] = useState(false);

  const formatTime = (time) => {
    const result = parseISO(time, { timeZone: 'Toronto/Canada' });

    const output = format(result, 'PPPp', { timeZone: 'Toronto/Canada' });

    return `${output}`;
  };

  const profilePic = () => {
    if (
      (row.profile_visible === true || row.profile_visible === false) &&
      techniqueChecker(row.technique)
    ) {
      return row.image;
    } else if (
      (row.profile_visible === true || row.profile_visible === false) &&
      row.technique === 'Shiatsu'
    ) {
      return 'https://clubdynasty.ca/wp-content/uploads/2023/10/Female_Silouhette-scaled-1.jpg';
    }
  };

  return (
    <React.Fragment>
      <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>

        <TableCell align="center">
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Avatar
              alt={row.name}
              src={profilePic()}
              style={{ marginRight: '10px' }}
              sx={{ width: 56, height: 56 }}
            />{' '}
            &nbsp;
            <Typography style={{ fontWeight: 'bold' }}>
              <Link to={`/session-review/result/${row.id}`}>{row.name}</Link>
            </Typography>
          </Box>
        </TableCell>

        <TableCell align="right">{row.count ? row.count : 0}</TableCell>
        <TableCell align="right">
          {row.score ? row.score.toFixed(2) : 0}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0, border: !open && 0 }}
          colSpan={6}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Suspense fallback={<LinearProgress />}>
              <ExpandableRowContent
                token={token}
                row={row}
                formatTime={formatTime}
              />
            </Suspense>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default ExpandableRowSessionResult;
