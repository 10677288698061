import axios from 'axios';
import {
  Card,
  CircularProgress,
  Container,
  Grid,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  IconButton,
  Stack,
} from '@mui/material';
import { useSelector } from 'react-redux';
import SessionCard from '../components/SessionCard';
import { useEffect, useState } from 'react';
import sessionsFormatter from '../utils/sessionsFormatter';
import { useNavigate } from 'react-router';
import masseuse_icon from '../img/masseuse_icon.svg';
import CancelIcon from '@mui/icons-material/Cancel';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';

const RecentSessionsScreen = () => {
  const navigate = useNavigate();

  const adminUserLogin = useSelector((state) => state.adminUserLogin);
  const { adminUserInfo } = adminUserLogin;

  const [removedItemIds, setRemovedItemIds] = useState([]);
  const [data, setData] = useState(null);

  useEffect(() => {
    const savedRemovedItemIds = localStorage.getItem('removedItemIds');
    if (savedRemovedItemIds) {
      setRemovedItemIds(JSON.parse(savedRemovedItemIds));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('removedItemIds', JSON.stringify(removedItemIds));
  }, [removedItemIds]);

  // const fetchRecentSessions = async () => {
  //   const { data } = await axios.get(
  //     `https://mydynasty.ca/ext/api/v1/surveys/sessions/today`,
  //     {
  //       headers: {
  //         Authorization: adminUserInfo.token,
  //       },
  //     }
  //   );
  //   return data;
  // };

  // today in YYYY-MM-DD format
  // const today = new Date().toISOString().split('T')[0];

  const fetchRecentSessions = async () => {
    const { data } = await axios.get(
      // `https://mydynasty.ca/ext/api/v1/surveys/sessions/?order_by=-start_time&start_date=${today}&end_date=${today}&survey_time&page=1&page_size=10000`,
      `https://mydynasty.ca/ext/api/v1/surveys/sessions/today`,
      {
        headers: {
          Authorization: adminUserInfo.token,
        },
      }
    );
    return data;
  };

  // const fetchRecentSessions = async () => {
  //   const { data } = await axios.get(
  //     `https://mydynasty.ca/ext/api/v1/surveys/sessions/?order_by=-start_time&start_date=${'2024-03-20'}&end_date=${'2024-03-20'}&survey_time&page=1&page_size=10000`,
  //     {
  //       headers: {
  //         Authorization: adminUserInfo.token,
  //       },
  //     }
  //   );
  //   return data.results;
  // };

  const refetchData = () => {
    fetchRecentSessions()
      .then((data) => setData(data))
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    fetchRecentSessions()
      .then((data) => setData(data))
      .catch((error) => console.error(error));

    const intervalId = setInterval(refetchData, 60000); // Refetch data every 1 minute

    return () => {
      clearInterval(intervalId); // Cleanup interval on component unmount
    };
  }, []);

  const handleRemove = (itemId) => {
    setRemovedItemIds((prevIds) => [...prevIds, itemId]);
  };

  const formattedResults = sessionsFormatter(data);

  const handleSurveyNavigate = (
    sessionId,
    masseuse,
    masseuse_id,
    otherMasseuses
  ) => {
    let otherMasseusesParams = '';
    if (otherMasseuses.length > 0) {
      otherMasseuses.map(
        (masseuse, i) =>
          (otherMasseusesParams += `&other_masseuse_name_${i + 1}=${
            masseuse.name
          }&other_masseuse_id_${i + 1}=${masseuse.id}`)
      );
    }
    navigate(
      `/session-review/${sessionId}?masseuse=${masseuse}&masseuse_id=${masseuse_id}${otherMasseusesParams}`
    );
  };

  if (!data) {
    return (
      <Container>
        <Typography variant="h1" align="center" mt={5}>
          <CircularProgress size={'3rem'} thickness={5} />
        </Typography>
      </Container>
    );
  }

  return (
    <Container>
      <Grid container spacing={2} mt={4} mb={4}>
        {formattedResults.length === 0 ? (
          <Grid item xs={12} sm={12}>
            <Card
              variant="outlined"
              style={{
                padding: '20px',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <div>
                  <h2
                    style={{
                      fontWeight: '700',
                    }}
                  >
                    No sessions available now
                  </h2>
                </div>
              </div>

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'flex-end',
                  marginTop: '30px',
                }}
              >
                <h4
                  style={{
                    fontSize: '18px',
                    fontWeight: '400',
                  }}
                >
                  Sessions will appear here once they are available
                </h4>
              </div>
            </Card>
          </Grid>
        ) : (
          formattedResults.map((masseuse, i) => (
            <Grid item xs={12} sm={6} key={i}>
              <TableContainer component={Paper} variant="outlined">
                <Table size="small">
                  <TableHead>
                    <TableRow
                      style={{
                        backgroundColor: '#f9f3ea',
                      }}
                    >
                      <TableCell align="center" colSpan={3}>
                        <h2
                          style={{
                            fontWeight: '700',
                          }}
                        >
                          <img src={masseuse_icon} alt="" /> &nbsp;
                          {masseuse.masseuse}
                        </h2>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">Customer</TableCell>
                      <TableCell align="center">Locker</TableCell>
                      <TableCell align="center">Survey</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {masseuse.sessions
                      .filter(
                        (session) =>
                          !removedItemIds.includes(session.session_id) &&
                          session.survey_status === 0
                      )
                      .map((session) => (
                        <TableRow
                          key={session.session_id}
                          sx={{
                            '&:last-child td, &:last-child th': { border: 0 },
                            backgroundColor: `${session?.session_type?.color}`,
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {session.member &&
                            session.member.name &&
                            session.member.name.length > 0
                              ? session.member.name.split(' ')[0]
                              : session.customer.split(' ')[0]}
                          </TableCell>

                          <TableCell align="center">{session.locker}</TableCell>

                          <TableCell align="center">
                            <Stack
                              direction="row"
                              spacing={1}
                              justifyContent="center"
                              alignItems="center"
                            >
                              <IconButton
                                size="small"
                                onClick={(e) =>
                                  handleSurveyNavigate(
                                    session.session_id,
                                    masseuse.masseuse,
                                    masseuse.masseuse_id,
                                    session.otherMasseuses
                                  )
                                }
                              >
                                <ArrowCircleRightIcon color="primary" />
                              </IconButton>

                              <IconButton
                                size="small"
                                onClick={() => handleRemove(session.session_id)}
                              >
                                <CancelIcon color="error" />
                              </IconButton>
                            </Stack>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          ))
        )}
      </Grid>
    </Container>
  );
};

export default RecentSessionsScreen;
