import './App.css';
import theme from './components/Theme';
import { ThemeProvider } from '@mui/material';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';

import AdminLoginScreen from './screens/AdminLoginScreen';
import RecentSessionsScreen from './screens/RecentSessionsScreen';
import SurveyScreen from './screens/SurveyScreen';
import setAdminAuthToken from './utils/setAdminAuthToken';

import { QueryClient, QueryClientProvider } from 'react-query';
import AdminHeader from './components/AdminHeader';
import ThankYouSurveyScreen from './screens/ThankYouSurveyScreen';
import isTokenValid from './utils/isTokenValid';
import { logout } from './actions/adminUserActions';
import SimpleListScreen from './screens/SimpleListScreen';
import HomeScreen from './screens/HomeScreen';
import PersistentDrawerLeft from './components/PersistentDrawerLeft';
import SimpleResultScreen from './screens/SimpleResultScreen';
import SessionSurveyResultScreen from './screens/SessionSurveyResultScreen';

import SessionSurveyDetailedResultScreen from './screens/SessionSurveyDetailedResultScreen';

if (localStorage.adminUserInfo) {
  setAdminAuthToken(JSON.parse(localStorage.adminUserInfo).token);
}

function App() {
  const dispatch = useDispatch();
  const queryClient = new QueryClient();

  const adminUserLogin = useSelector((state) => state.adminUserLogin);
  const { loading: loadingUserAdminInfo, adminUserInfo } = adminUserLogin;

  useEffect(() => {
    if (localStorage.adminUserInfo) {
      const isAdminTokenValid = isTokenValid(
        JSON.parse(localStorage.adminUserInfo).token
      );
      if (!isAdminTokenValid) {
        dispatch(logout());
      }
    }
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <Router>
          {adminUserInfo && (
            <>
              <PersistentDrawerLeft theme={theme} />
              {/* <AdminHeader theme={theme} /> */}
            </>
          )}

          <Routes>
            {!adminUserInfo && (
              <>
                <Route
                  path="/admin-login"
                  element={<AdminLoginScreen />}
                ></Route>
              </>
            )}
            {adminUserInfo && (
              <>
                <Route path="/" element={<HomeScreen />}></Route>
                <Route
                  path="/session-review"
                  element={<RecentSessionsScreen />}
                ></Route>
                <Route
                  path="/session-review/result"
                  element={<SessionSurveyResultScreen />}
                ></Route>
                <Route
                  path="/session-review/detailed-result"
                  element={<SessionSurveyDetailedResultScreen />}
                ></Route>
                <Route
                  path="/session-review/:id"
                  element={<SurveyScreen />}
                ></Route>

                <Route path="/simple" element={<SimpleListScreen />}></Route>
                <Route
                  path="/simple/result"
                  element={<SimpleResultScreen />}
                ></Route>
                <Route
                  path="/session-review/thank-you"
                  element={<ThankYouSurveyScreen />}
                ></Route>
              </>
            )}
            <Route
              path="*"
              element={<Navigate to={adminUserInfo ? '/' : '/admin-login'} />}
            />
          </Routes>
        </Router>
      </ThemeProvider>
    </QueryClientProvider>
  );
}

export default App;
