import axios from 'axios';
import {
  ADMIN_USER_LOGIN_FAIL,
  ADMIN_USER_LOGIN_REQUEST,
  ADMIN_USER_LOGIN_SUCCESS,
  ADMIN_USER_LOGOUT,
} from '../constants/adminUserConstants';
import formatError from '../utils/errors';
import { setAlert } from './alertActions';
import { API_URI, CD_API_URI } from '../constants/appConstants';

function parseDjangoError(errorResponse) {
  const errors = {};

  // Check if the error response is a standard Django response with a "detail" key
  if (errorResponse.hasOwnProperty('detail')) {
    errors['non_field_errors'] = [errorResponse.detail];
  } else {
    // Loop through each field in the error response
    for (const fieldName in errorResponse) {
      if (errorResponse.hasOwnProperty(fieldName)) {
        // Get the error messages for the current field
        const fieldErrors = errorResponse[fieldName];

        // If the error messages are an array, add them to the errors object
        if (Array.isArray(fieldErrors)) {
          errors[fieldName] = fieldErrors;
        } else {
          // If the error messages are not an array, assume they are a string and add them as a single-item array
          errors[fieldName] = [fieldErrors];
        }
      }
    }
  }

  return errors;
}

export const adminLogin = (username, password) => async (dispatch) => {
  try {
    dispatch({
      type: ADMIN_USER_LOGIN_REQUEST,
    });

    const config = {
      headers: {
        'Content-Type': 'Application/json',
      },
    };

    const { data } = await axios.post(
      `${CD_API_URI}/login/`,
      { username, password },
      config
    );

    // data.user.fresh_login = true;
    // data.user.kind = 6;

    dispatch({
      type: ADMIN_USER_LOGIN_SUCCESS,
      payload: { ...data, token: `Bearer ${data.token}` },
    });

    localStorage.setItem(
      'adminUserInfo',
      JSON.stringify({ ...data, token: `Bearer ${data.token}` })
    );
  } catch (error) {
    const newErrors = parseDjangoError(error);
    // console.log(newErrors);
    const errorText = formatError(error.response.data);
    dispatch(setAlert(errorText, 'error'));
    dispatch({
      type: ADMIN_USER_LOGIN_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};

export const logout = () => (dispatch) => {
  localStorage.removeItem('adminUserInfo');
  localStorage.removeItem('removedItemIds');
  localStorage.clear();
  dispatch({ type: ADMIN_USER_LOGOUT });
  delete axios.defaults.headers.common['Authorization'];
};
