import axios from 'axios';
import {
  SUBMIT_SURVEY_FAIL,
  SUBMIT_SURVEY_REQUEST,
  SUBMIT_SURVEY_SUCCESS,
} from '../constants/surveyConstants';
import formatError from '../utils/errors';
import { setAlert } from './alertActions';

export const submitSurvey =
  (surveyAnswers, sessionId, masseuse_id) => async (dispatch, getState) => {
    try {
      dispatch({
        type: SUBMIT_SURVEY_REQUEST,
      });

      const {
        adminUserLogin: { adminUserInfo },
      } = getState();

      const config = {
        headers: {
          'Content-Type': 'Application/json',
          Authorization: adminUserInfo.token,
        },
      };

      // console.log({
      //   answers: surveyAnswers,
      // });

      const { data } = await axios.post(
        // `${CD_API_URI}/survey/questions/submission/${sessionId}/`,
        `https://mydynasty.ca/ext/api/v1/surveys/answers/submit/${sessionId}/`,
        {
          answers: surveyAnswers,
          masseuse: masseuse_id,
        },
        config
      );

      dispatch({
        type: SUBMIT_SURVEY_SUCCESS,
        payload: data,
      });

      // dispatch(setAlert('Thank you for submitting the survey', 'success'));
    } catch (error) {
      console.log(error.response);
      const errorText = formatError(error.response.data);
      dispatch(setAlert(errorText, 'error'));

      dispatch({
        type: SUBMIT_SURVEY_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
