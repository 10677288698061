import { Container, Grid, LinearProgress, Typography } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import MasseuseCard from '../components/MasseuseCard';
import useAvailableMasseuses from '../hooks/useAvailableMasseuses';

const SimpleListScreen = () => {
  const adminUserLogin = useSelector((state) => state.adminUserLogin);
  const { adminUserInfo } = adminUserLogin;

  const { availableMasseuses, isLoading, error } = useAvailableMasseuses(
    adminUserInfo.token
  );

  return (
    <Container>
      <Grid container spacing={2} mt={3} mb={2}>
        <Grid item xs={12}>
          <Typography variant="h5" component="div" gutterBottom>
            Simple Survey
          </Typography>
        </Grid>
        {isLoading ? (
          <Grid item xs={12}>
            <LinearProgress />
          </Grid>
        ) : (
          availableMasseuses &&
          availableMasseuses.map((d) => (
            <MasseuseCard key={d.id} masseuse={d} token={adminUserInfo.token} />
          ))
        )}
      </Grid>
    </Container>
  );
};

export default SimpleListScreen;
