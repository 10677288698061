import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
  styled,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import StarIcon from '@mui/icons-material/Star';
import CloseIcon from '@mui/icons-material/Close';
import useMasseuseDetails from '../hooks/useMasseuseDetails';
import StarRating from './StarRating';
import { useDispatch, useSelector } from 'react-redux';
import { submitSimpleSurvey } from '../actions/simpleSurveyActions';
import { setAlert } from '../actions/alertActions';
import Message from './Message';
import { SIMPLE_SUBMIT_SURVEY_RESET } from '../constants/simpleSurveyConstants';
import techniqueChecker from '../utils/techniqueChecker';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const MasseuseCard = ({ masseuse, token }) => {
  const dispatch = useDispatch();

  const [dialogOpen, setDialogOpen] = useState(false);
  const [value, setValue] = useState(0);

  const alert = useSelector((state) => state.alert);
  const { msg } = alert;

  const simpleSurveySubmit = useSelector((state) => state.simpleSurveySubmit);
  const {
    loading: isSimpleSubmitLoading,
    error: simpleSubmitError,
    success,
  } = simpleSurveySubmit;

  const { masseuseDetails, isLoading, error } = useMasseuseDetails(
    masseuse.id,
    token
  );

  const handleAvatarDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleAvatarDialogClose = () => {
    setDialogOpen(false);
    // dispatch({ type: SIMPLE_SUBMIT_SURVEY_RESET });
  };

  const handleAnswerChange = (masseuseId, value) => {
    if (masseuseDetails?.id === masseuseId) {
      setValue(value);
    }
  };

  useEffect(() => {
    // Set up a timer with setTimeout
    const timerId = setTimeout(() => {
      // Your code to be executed after the delay
      dispatch({ type: SIMPLE_SUBMIT_SURVEY_RESET });
    }, 100); // 1000 milliseconds (1 second)

    // Cleanup function
    return () => {
      // Clear the timer if the component is unmounted or dependencies change
      clearTimeout(timerId);
    };
  }, [dialogOpen]);

  // useEffect(() => {
  //   if (dialogOpen === false) {
  //     dispatch({ type: SIMPLE_SUBMIT_SURVEY_RESET });
  //   }
  // }, [dialogOpen]);

  const submitRating = () => {
    if (value === 0 || value === null) {
      dispatch(setAlert('Please choose a rating before submitting', 'error'));
      return;
    } else {
      const simpleAnswer = {
        value: value,
        masseuse: masseuseDetails.id,
        working_date: new Date().toISOString().split('T')[0],
      };
      dispatch(submitSimpleSurvey(simpleAnswer));
    }
  };

  return (
    <Grid item xs={12} sm={6}>
      <BootstrapDialog
        onClose={handleAvatarDialogClose}
        open={dialogOpen}
        fullWidth
        maxWidth={'xl'}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Rate {masseuseDetails?.name}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleAvatarDialogClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent
          dividers
          style={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          {success ? (
            <>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'column',
                  marginTop: '10px',
                  marginBottom: '10px',
                }}
              >
                <img
                  src={
                    'https://clubdynasty.ca/wp-content/uploads/2023/11/Thank-You-Graphic.png'
                  }
                  alt="thank you"
                  width={400}
                />
              </div>
              <Typography component="div" variant="h5" mt={5} mb={5}>
                We value your input. Thanks for taking the time to share.
              </Typography>
            </>
          ) : (
            <>
              {techniqueChecker(masseuseDetails?.technique) && (
                <img
                  src={masseuseDetails?.image}
                  alt={masseuseDetails?.name}
                  style={{ width: '400px' }}
                />
              )}
              {masseuseDetails?.technique === 'Shiatsu' && (
                <img
                  src={
                    'https://clubdynasty.ca/wp-content/uploads/2023/10/Female_Silouhette-scaled-1.jpg'
                  }
                  alt={masseuseDetails?.name}
                  style={{ width: '400px' }}
                />
              )}

              <Typography component="div" variant="h5" mt={2} mb={2}>
                Please rate your experience with{' '}
                <strong>{masseuseDetails?.name}</strong>
              </Typography>

              <StarRating
                handleAnswerChange={handleAnswerChange}
                questionId={masseuseDetails?.id}
              />
              <Typography component="div" variant="h5" mt={2}></Typography>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAvatarDialogClose} size="large">
            Close
          </Button>
          {!success && (
            <Button
              autoFocus
              onClick={submitRating}
              variant="contained"
              disableElevation
              size="large"
            >
              Submit
              {isSimpleSubmitLoading && (
                <>
                  &nbsp;
                  <CircularProgress color="white" size={20} thickness={5} />
                </>
              )}
            </Button>
          )}
        </DialogActions>
        {msg?.length > 0 && <Message />}
      </BootstrapDialog>
      {masseuseDetails && !isLoading && (
        <Card
          key={masseuse.id}
          variant="outlined"
          style={{
            padding: '20px 0px 20px 20px',
            /* top | right | bottom | left */
          }}
          sx={{ display: 'flex' }}
        >
          <CardMedia
            component="img"
            sx={{ width: 151 }}
            image={
              techniqueChecker(masseuseDetails.technique)
                ? masseuseDetails.image
                : 'https://clubdynasty.ca/wp-content/uploads/2023/10/Female_Silouhette-scaled-1.jpg'
            }
            title={masseuseDetails.name}
          />

          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <CardContent sx={{ flex: '1 0 auto' }}>
              <Typography component="div" variant="h5">
                {masseuseDetails.name}
              </Typography>
              <Typography
                variant="subtitle1"
                color="text.secondary"
                component="div"
              >
                {masseuseDetails.services}
              </Typography>
            </CardContent>
            <Box sx={{ display: 'flex', alignItems: 'center', pl: 1, pb: 1 }}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'flex-end',
                }}
              >
                <Chip
                  label="Rate"
                  color="primary"
                  deleteIcon={<StarIcon />}
                  onClick={handleAvatarDialogOpen}
                  //   onClick={(e) => handleSurveyNavigate(masseuse.id)}
                  onDelete={handleAvatarDialogOpen}
                  style={{
                    margin: '0px 0px 5px 5px',
                  }} /* top | right | bottom | left */
                />
              </div>
            </Box>
          </Box>
        </Card>
      )}
    </Grid>
  );
};

export default MasseuseCard;
