export default function calculateScorePercentage(results) {
  const scoreCounts = {};
  const totalScores = results?.length;

  // Dynamically count occurrences of each score
  results?.forEach((result) => {
    const scoreKey = result.score.toString();
    if (scoreCounts[scoreKey]) {
      scoreCounts[scoreKey]++;
    } else {
      scoreCounts[scoreKey] = 1;
    }
  });

  // Construct an array with each unique score and its percentage
  const scorePercentages = [];
  for (let score in scoreCounts) {
    scorePercentages.push({
      score: parseFloat(score),
      question: 5, // Assuming all have the same 'question' value for simplicity
      scorePercentage: scoreCounts[score] / totalScores,
    });
  }

  return scorePercentages;
}
