import {
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import CircularProgress from '@mui/material/CircularProgress';

// import LoginIllustration from '../../img/Login_Artwork_1.svg';
import { Link, useNavigate } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import Message from '../components/Message';

import VIP_BG from '../img/VIP-BG.jpg';
import CD_LOGO from '../img/cd-logo.png';

import { REMOVE_ALERT } from '../constants/alertConstants';
import { adminLogin } from '../actions/adminUserActions';

const AdminLoginScreen = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const [values, setValues] = useState({
    username: '',
    password: '',
    showPassword: false,
  });

  const adminUserLogin = useSelector((state) => state.adminUserLogin);
  const { loading, adminUserInfo } = adminUserLogin;

  const alert = useSelector((state) => state.alert);
  const { msg } = alert;

  useEffect(() => {
    if (adminUserInfo) {
      navigate('/');
    }
  }, [adminUserInfo]);

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(adminLogin(values.username, values.password));
  };

  return (
    <div
      style={{
        background: `url(${VIP_BG}) center center no-repeat`,
        backgroundSize: 'cover',
        boxShadow: 'inset 0 0 0 2000px rgba(0, 0, 0, 0.3)',
      }}
    >
      <Container
        style={{
          height: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Grid container alignItems="center" justifyContent="center">
          <Grid item xs={12} sm={4} className="grid-center">
            <Card
              sx={{ minWidth: 275, background: 'rgba(255,255,255,0.9)' }}
              variant="outlined"
            >
              <CardContent style={{ textAlign: 'center', padding: '30px' }}>
                <img src={CD_LOGO} width="250px" alt="Club Dynasty Logo" />
                <Typography variant="h2" gutterBottom>
                  Admin Login
                </Typography>
                <form onSubmit={(e) => onSubmit(e)}>
                  <TextField
                    size="small"
                    variant="outlined"
                    type="text"
                    value={values.username}
                    onChange={handleChange('username')}
                    label="Username"
                    style={{ width: '100%', marginBottom: '20px' }}
                  />

                  <TextField
                    variant="outlined"
                    size="small"
                    style={{ width: '100%', marginBottom: '20px' }}
                    type={values.showPassword ? 'text' : 'password'}
                    value={values.password}
                    onChange={handleChange('password')}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {values.showPassword ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    label="Password"
                  />

                  <Button
                    variant="contained"
                    disableElevation
                    size="small"
                    style={{ width: '100%', marginBottom: '20px' }}
                    type="submit"
                  >
                    Login{' '}
                    {loading && (
                      <>
                        &nbsp;
                        <CircularProgress
                          color="white"
                          size={20}
                          thickness={5}
                        />
                      </>
                    )}
                  </Button>
                </form>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        {msg?.length > 0 && <Message />}
      </Container>
    </div>
  );
};

export default AdminLoginScreen;
