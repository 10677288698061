import {
  Card,
  CardContent,
  FormControl,
  Grid,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import { ANS_KIND_THREE, ANS_KIND_TWO } from '../constants/appConstants';
import useDetailedResults from '../hooks/useDetailedResults';
import calculateScorePercentage from '../utils/calculateScorePercentage';
import { AnswerLineChart } from './AnswerLineChart';
import AnswerPieThreeChoices from './AnswerPieThreeChoices';
import AnswerPieTwoChoices from './AnswerPieTwoChoices';

const AnswerChart = ({ question, token }) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [aggregate, setAggregate] = useState(
    question.kind === 1 ? 'month' : ''
  );
  const [pageSize, setPageSize] = useState(1000000);

  const { rows, isLoading, error } = useDetailedResults({
    token: token,
    page: 1,
    page_size: pageSize,
    start_date: startDate?.toISOString().split('T')[0],
    end_date: endDate?.toISOString().split('T')[0],
    question: question.id,
    aggregate: aggregate,
  });

  const handleStartDateChange = (newValue) => {
    setStartDate(newValue);
  };

  const handleEndDateChange = (newValue) => {
    setEndDate(newValue);
  };

  const handleAggregateChange = (event) => {
    setAggregate(event.target.value);
  };

  const handlePageSizeChange = (event) => {
    setPageSize(event.target.value);
  };

  return (
    <Card variant="outlined">
      <CardContent>
        <Typography
          variant="h5"
          component="div"
          gutterBottom
          mt={1}
          mb={1}
          textAlign={'center'}
        >
          {question.id}. {question.statement}{' '}
          {question.id === 1 ? '"MASSEUSE"' : null}
        </Typography>
        <Container
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Grid container spacing={2} maxWidth={'md'} mt={1} mb={2}>
            <Grid item xs={12} sm={question.kind === 1 ? 3 : 6}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  inputFormat="MM/dd/yyyy"
                  label="Start Date"
                  value={startDate}
                  onChange={handleStartDateChange}
                  disableFuture
                  slotProps={{
                    textField: {
                      size: 'small',
                      fullWidth: true,
                    },
                  }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={question.kind === 1 ? 3 : 6}>
              <Tooltip title={!startDate && 'Select start date first'}>
                <div>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      inputFormat="MM/dd/yyyy"
                      label="End Date"
                      value={endDate}
                      onChange={handleEndDateChange}
                      disableFuture
                      minDate={startDate && startDate}
                      disabled={!startDate}
                      slotProps={{
                        textField: {
                          size: 'small',
                          fullWidth: true,
                        },
                      }}
                    />
                  </LocalizationProvider>{' '}
                </div>
              </Tooltip>
            </Grid>
            {question.kind === 1 && (
              <Grid item xs={12} sm={3}>
                <FormControl fullWidth>
                  <InputLabel id="aggregate-select-label" size="small">
                    Aggregate
                  </InputLabel>
                  <Select
                    labelId="aggregate-select-label"
                    id="aggregate-select"
                    value={aggregate}
                    label="Aggregate"
                    onChange={handleAggregateChange}
                    size="small"
                  >
                    <MenuItem value={''}>N/A</MenuItem>
                    <MenuItem value={'day'}>Day</MenuItem>
                    <MenuItem value={'week'}>Week</MenuItem>
                    <MenuItem value={'month'}>Month</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            )}

            {question.kind === 1 && (
              <Grid item xs={12} sm={3}>
                <FormControl fullWidth>
                  <InputLabel id="pageSize-select-label" size="small">
                    Page Size
                  </InputLabel>
                  <Select
                    labelId="pageSize-select-label"
                    id="pageSize-select"
                    value={pageSize}
                    label="PageSize"
                    onChange={handlePageSizeChange}
                    size="small"
                  >
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={50}>50</MenuItem>
                    <MenuItem value={100}>100</MenuItem>
                    <MenuItem value={500}>500</MenuItem>
                    <MenuItem value={1000}>1000</MenuItem>
                    <MenuItem value={10000}>10000</MenuItem>
                    <MenuItem value={100000}>100000</MenuItem>
                    <MenuItem value={1000000}>1000000</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            )}
          </Grid>
        </Container>

        {question.kind === 1 && (
          <>
            <div
              style={{
                backgroundColor: '#FFF4E5',
                padding: '10px',
                borderRadius: '10px',
                marginBottom: '20px',
              }}
            >
              {isLoading ? (
                <LinearProgress
                  sx={{ marginTop: '1rem', marginBottom: '1rem' }}
                />
              ) : (
                <>
                  <Typography variant="h1" textAlign={'center'}>
                    {(
                      rows?.results.reduce((acc, { score }) => acc + score, 0) /
                      rows?.results.length
                    ).toFixed(2)}
                  </Typography>
                  {aggregate === '' && (
                    <Typography variant="body1" textAlign={'center'}>
                      {rows?.results.length} submissions
                    </Typography>
                  )}
                </>
              )}
            </div>

            <AnswerLineChart results={rows?.results} question={question} />
          </>
        )}
        {question.kind === 2 && (
          <>
            <AnswerPieTwoChoices
              results={calculateScorePercentage(rows?.results)}
            />
            <div style={{ textAlign: 'center', marginTop: '1rem' }}>
              {calculateScorePercentage(rows?.results).map((r, i) => (
                <span key={i}>
                  <b>{ANS_KIND_TWO[r.score]}:</b>{' '}
                  {(r.scorePercentage * 100).toFixed(2)}% &nbsp;
                </span>
              ))}
            </div>
            <div
              style={{
                backgroundColor: '#FFF4E5',
                padding: '10px',
                borderRadius: '10px',
                marginTop: '20px',
              }}
            >
              {isLoading ? (
                <LinearProgress
                  sx={{ marginTop: '1rem', marginBottom: '1rem' }}
                />
              ) : (
                aggregate === '' && (
                  <Typography variant="body1" textAlign={'center'}>
                    {rows?.results.length} submissions
                  </Typography>
                )
              )}
            </div>
          </>
        )}
        {question.kind === 3 && (
          <>
            <AnswerPieThreeChoices
              results={calculateScorePercentage(rows?.results)}
            />
            <div style={{ textAlign: 'center', marginTop: '1rem' }}>
              {calculateScorePercentage(rows?.results).map((r, i) => (
                <span key={i}>
                  <b>{ANS_KIND_THREE[r.score]}:</b>{' '}
                  {(r.scorePercentage * 100).toFixed(2)}% &nbsp;
                </span>
              ))}
            </div>
            <div
              style={{
                backgroundColor: '#FFF4E5',
                padding: '10px',
                borderRadius: '10px',
                marginTop: '20px',
              }}
            >
              {isLoading ? (
                <LinearProgress
                  sx={{ marginTop: '1rem', marginBottom: '1rem' }}
                />
              ) : (
                <Typography variant="body1" textAlign={'center'}>
                  {rows?.results.length} submissions {aggregate}
                </Typography>
              )}
            </div>
          </>
        )}
      </CardContent>
    </Card>
  );
};

export default AnswerChart;
