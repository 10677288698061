import {
  Avatar,
  Box,
  Collapse,
  Container,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  LinearProgress,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import useSimpleAggregates from '../hooks/useSimpleAggregates';
import { useSelector } from 'react-redux';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import ExpandableRow from '../components/ExpandableRow';
import { useNavigate } from 'react-router';
import userChecker from '../utils/userChecker';

const SimpleResultScreen = () => {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('calories');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [recentFormPageSize, setRecentFormPageSize] = useState(5);
  const [technique, setTechnique] = useState('');
  const [status, setStatus] = useState(true);

  const adminUserLogin = useSelector((state) => state.adminUserLogin);
  const { adminUserInfo } = adminUserLogin;
  const { rows, isLoading, error } = useSimpleAggregates({
    token: adminUserInfo.token,
    start_date: startDate?.toISOString().split('T')[0],
    end_date: endDate?.toISOString().split('T')[0],
    technique,
    status,
  });

  const handleSortRequest = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const sortedRows = React.useMemo(() => {
    let sortableData = [...(rows ?? [])];
    if (orderBy) {
      sortableData.sort((a, b) => {
        if (a[orderBy] < b[orderBy]) {
          return order === 'asc' ? -1 : 1;
        }
        if (a[orderBy] > b[orderBy]) {
          return order === 'asc' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableData;
  }, [rows, orderBy, order]);

  const handleStartDateChange = (newValue) => {
    setStartDate(newValue);
  };

  const handleEndDateChange = (newValue) => {
    setEndDate(newValue);
  };

  let navigate = useNavigate();

  useEffect(() => {
    if (!userChecker(adminUserInfo.user.kind)) {
      navigate('/');
    }
  }, []);

  return (
    <Container>
      <Grid container spacing={2} mt={7} mb={2}>
        <Grid item xs={12} sm={3}>
          <Typography variant="h5" component="div">
            Simple Survey Results
          </Typography>
        </Grid>
        <Grid item xs={12} sm={2.25}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              inputFormat="MM/dd/yyyy"
              label="Start Date"
              value={startDate}
              onChange={handleStartDateChange}
              disableFuture
              slotProps={{
                textField: {
                  size: 'small',
                },
              }}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} sm={2.25}>
          <Tooltip title={!startDate && 'Select start date first'}>
            <div>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  inputFormat="MM/dd/yyyy"
                  label="End Date"
                  value={endDate}
                  onChange={handleEndDateChange}
                  disableFuture
                  minDate={startDate && startDate}
                  disabled={!startDate}
                  slotProps={{
                    textField: {
                      size: 'small',
                    },
                  }}
                />
              </LocalizationProvider>{' '}
            </div>
          </Tooltip>
        </Grid>
        <Grid item xs={6} sm={2.25}>
          <FormControl fullWidth size="small">
            <InputLabel id="select-technique">Technique</InputLabel>
            <Select
              labelId="select-technique"
              value={technique}
              label="Technique"
              onChange={(e) => setTechnique(e.target.value)}
            >
              <MenuItem value={''}>No selection</MenuItem>
              <MenuItem value={'Sensual'}>Sensual</MenuItem>
              <MenuItem value={'Shiatsu'}>Shiatsu</MenuItem>
            </Select>{' '}
          </FormControl>
        </Grid>
        <Grid item xs={6} sm={2.25}>
          <FormControl fullWidth size="small">
            <InputLabel id="select-status">Status</InputLabel>
            <Select
              labelId="select-status"
              value={status}
              label="Status"
              onChange={(e) => setStatus(e.target.value)}
            >
              <MenuItem value={null}>All</MenuItem>
              <MenuItem value={true}>Active</MenuItem>
              <MenuItem value={false}>Inactive</MenuItem>
            </Select>{' '}
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <TableContainer component={Paper} variant="outlined">
            <Table
              sx={{ minWidth: 650 }}
              aria-label="simple results table"
              size="small"
            >
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell align="left">
                    <TableSortLabel
                      active={orderBy === 'name'}
                      direction={order}
                      onClick={() => handleSortRequest('name')}
                    >
                      Masseuse
                    </TableSortLabel>
                  </TableCell>
                  {/* <TableCell align="left">
                    <TableSortLabel
                      active={orderBy === 'technique'}
                      direction={order}
                      onClick={() => handleSortRequest('technique')}
                    >
                      Technique
                    </TableSortLabel>
                  </TableCell> */}
                  <TableCell align="right">
                    <TableSortLabel
                      active={orderBy === 'count'}
                      direction={order}
                      onClick={() => handleSortRequest('count')}
                    >
                      Reviews
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="right">
                    <TableSortLabel
                      active={orderBy === 'average'}
                      direction={order}
                      onClick={() => handleSortRequest('average')}
                    >
                      Average
                    </TableSortLabel>
                  </TableCell>
                  {/* <TableCell align="center">
                    Recent Form &nbsp;
                    <Select
                      value={recentFormPageSize}
                      onChange={(e) => setRecentFormPageSize(e.target.value)}
                      size="small"
                    >
                      <MenuItem value={5}>Latest 5</MenuItem>
                      <MenuItem value={10}>Latest 10</MenuItem>
                      <MenuItem value={15}>Latest 15</MenuItem>
                    </Select>
                  </TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading && (
                  <TableRow>
                    <TableCell align="left" colSpan={6}>
                      <br />
                      <LinearProgress />
                      <br />
                    </TableCell>
                  </TableRow>
                )}
                {!isLoading && rows && rows.length === 0 && (
                  <TableCell colSpan={4} align="center">
                    <Typography>
                      No data found. Try changing the filters.
                    </Typography>
                  </TableCell>
                )}
                {!isLoading &&
                  rows &&
                  rows.length > 0 &&
                  sortedRows.map((row) => (
                    <ExpandableRow
                      key={row.masseuse}
                      row={row}
                      token={adminUserInfo.token}
                      recentFormPageSize={recentFormPageSize}
                    />
                  ))}
                {!isLoading && rows && rows.length > 0 && (
                  <TableRow>
                    <TableCell colSpan={2} align="right">
                      <Typography style={{ fontWeight: 'bold' }}>
                        Total
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      {rows.reduce((acc, curr) => acc + curr.count, 0)}
                    </TableCell>
                    <TableCell align="right">
                      {(
                        rows.reduce(
                          (acc, curr) => acc + curr.count * curr.average,
                          0
                        ) / rows.reduce((acc, curr) => acc + curr.count, 0)
                      ).toFixed(2)}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Container>
  );
};

export default SimpleResultScreen;
