import { useQuery } from 'react-query';
import axios from 'axios';

function useDetailedResults({
  token,
  masseuse,
  page,
  page_size,
  start_date,
  end_date,
  session,
  question,
  aggregate,
}) {
  const fetchSessionResultList = async () => {
    const endpoint = `https://mydynasty.ca/ext/api/v1/surveys/answers/`;
    const headers = {
      Authorization: token,
    };

    const params = {
      page: page ?? '',
      page_size: page_size ?? '',
      masseuse: masseuse ?? '',
      session: session ?? '',
      start_date: start_date ?? '',
      end_date: end_date ?? '',
      question: question ?? '',
      score: true,
      order_by: 'question',
      aggregate: aggregate ?? '',
    };

    try {
      const { data } = await axios.get(endpoint, { params, headers });

      return data;
    } catch (error) {
      throw new Error('Failed to fetch Simple List');
    }
  };

  const queryKey = [
    'sessionResultList',
    question,
    start_date,
    end_date,
    aggregate,
    page_size,
  ];

  const { data, error, isLoading } = useQuery(
    queryKey,
    fetchSessionResultList,
    {
      cacheTime: Infinity,
      staleTime: Infinity,
      onError: (err) => {
        console.error(err);
      },
    }
  );

  return {
    rows: data,
    isLoading,
    error,
  };
}

export default useDetailedResults;
