import * as React from 'react';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { useEffect } from 'react';

import { REMOVE_ALERT } from '../constants/alertConstants';
import { Badge, LinearProgress } from '@mui/material';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function Message() {
  const dispatch = useDispatch();

  const [open, setOpen] = useState(true);

  const alert = useSelector((state) => state.alert);
  const { msg, alertType } = alert;

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
    dispatch({ type: REMOVE_ALERT });
  };

  const [seconds, setSeconds] = useState(5);

  useEffect(() => {
    let interval = null;
    interval = setInterval(() => {
      setSeconds((seconds) => seconds - 1);
      if (seconds === 1) {
        dispatch({ type: REMOVE_ALERT });
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [seconds]);

  if (msg.length > 0) {
    return (
      <Snackbar
        open={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={handleClose}
          severity={alertType}
          sx={{ width: '100%' }}
        >
          {msg}
        </Alert>
      </Snackbar>
    );
  } else return null;
}
