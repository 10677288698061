import { Button, Container, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { SUBMIT_SURVEY_RESET } from '../constants/surveyConstants';
import { REMOVE_ALERT } from '../constants/alertConstants';

const ThankYouSurveyScreen = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: SUBMIT_SURVEY_RESET });
    dispatch({ type: REMOVE_ALERT });
  }, []);

  return (
    <Container>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          marginTop: '50px',
          marginBottom: '10px',
        }}
      >
        <img
          src={
            'https://clubdynasty.ca/wp-content/uploads/2023/11/Thank-You-Graphic.png'
          }
          alt="thank you"
          width={400}
        />
      </div>{' '}
      <Typography variant="h1" align="center" mb={3}>
        Thank You
      </Typography>
      <Typography variant="p">
        We sincerely appreciate you taking the time to submit the survey and
        share your valuable feedback with us. It is through the insights and
        opinions of our customers that we are able to continuously improve our
        services and exceed expectations. Your feedback will help us identify
        areas where we can improve and ensure that we maintain the highest level
        of customer satisfaction. Thank you again for your support and for
        choosing our services.
      </Typography>{' '}
      <br />
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          marginTop: '2rem',
          marginBottom: '10px',
        }}
      >
        <Link to="/">
          <Button variant="outlined" disableElevation>
            Back to Home
          </Button>
        </Link>
      </div>
    </Container>
  );
};

export default ThankYouSurveyScreen;
