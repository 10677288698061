import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export function AnswerLineChart({ results, question }) {
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: false,
        text: `${question.statement}`,
      },
    },
  };

  const labels = results?.map((r) => {
    return r.period.toString().split('T')[0];
  });

  const data = {
    labels,
    datasets: [
      {
        label: `${question.statement} ${question.id === 1 ? '"MASSEUSE"' : ''}`,
        data: results?.map((r) => {
          return r.score;
        }),
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },
    ],
  };

  return <Line options={options} data={data} />;
}
