import { useQuery } from 'react-query';
import axios from 'axios';

function useSurveyQuestions({ token }) {
  const fetchSurveyQuestions = async () => {
    const endpoint = `https://mydynasty.ca/ext/api/v1/surveys/questions/`;
    const headers = {
      Authorization: token,
    };

    try {
      const { data } = await axios.get(endpoint, { headers });
      return data;
    } catch (error) {
      throw new Error('Failed to fetch Question List');
    }
  };

  const queryKey = ['questionList'];

  const { data, error, isLoading } = useQuery(queryKey, fetchSurveyQuestions, {
    cacheTime: Infinity,
    staleTime: Infinity,
    onError: (err) => {
      console.error(err);
    },
  });

  return {
    questions: data,
    isLoading,
    error,
  };
}

export default useSurveyQuestions;
