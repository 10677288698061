import React from 'react';
import { TableRow, TableCell, Typography } from '@mui/material';

const SessionSurveyResultTotal = ({ token, rows }) => {
  const filteredRows = rows?.filter((row) => row.score !== null);

  const totalScore = filteredRows?.reduce((acc, curr) => acc + curr.score, 0);
  const totalReviews = filteredRows?.reduce((acc, curr) => acc + curr.count, 0);
  const averageScore = (totalScore / filteredRows?.length).toFixed(2);

  return (
    <TableRow>
      <TableCell colSpan={2} align="right">
        <Typography style={{ fontWeight: 'bold' }}>Total</Typography>
      </TableCell>
      <TableCell align="right">{totalReviews}</TableCell>
      <TableCell align="right">{averageScore}</TableCell>
    </TableRow>
  );
};

export default SessionSurveyResultTotal;
